import request from '@/utils/request'

// 获取即将到期卡列表
export function findCardServiceManagesUnexpired(params) {
  return request({
    url: `/card_service_manages/unexpired`,
    method: 'get',
    params
  })
}

// 导出服务周期即将到期的卡
export function exportCardServiceManagesUnexpired(data) {
  return request({
    url: `/card_service_manages/unexpired/export`,
    method: 'post',
    data
  })
}

// 获取即将到期卡列表
export function findCardServiceManagesExpired(params) {
  return request({
    url: `/card_service_manages/expired`,
    method: 'get',
    params
  })
}

// 导出服务周期已到期的卡
export function exportCardServiceManagesExpired(data) {
  return request({
    url: `/card_service_manages/expired/export`,
    method: 'post',
    data
  })
}
