<template>
  <div>
    <search-card-service-manages
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchCardServiceManages from '@/views/card_service_manages/SearchExpired'
import Pagination from '@/components/Pagination'
import { findCardServiceManagesExpired, exportCardServiceManagesExpired } from '@/api/card_service_manages'
import { exportExcel } from '@/api/excel'
export default {
  name: 'CardServiceManages',
  components: {
    SearchCardServiceManages,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: '客户',
          dataIndex: 'top_agent_name'
        },
        {
          title: '服务周期起始时间',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '预计销卡时间',
          dataIndex: 'expect_cancel_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'expect_cancel_time' ? this.sort.sort_order : false
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'product_price'
        },
        {
          title: '责任销售',
          dataIndex: 'sales_full_names'
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCardServiceManagesExpired(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportCardServiceManagesExpired(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
